import User from "../User/User";
import UserCardOrange from "../UserCardOrange/UserCardOrange";


export default function Home() {
    return (
        <div >
            <User/>
            <UserCardOrange/>
        </div>
    )
}
import RegisterCardOrangeComponents from '../components/Register/RegisterCardOrange/RegisterCardOrange';


export default function RegisterCardOrange() {
    return (
        <div>
            

            <RegisterCardOrangeComponents/>
        </div>
    )
}
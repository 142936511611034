import RegisterComponents from '../components/Register/Register';


export default function Register() {
    return (
        <div>
            

            <RegisterComponents/>
        </div>
    )
}